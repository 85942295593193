import { useEffect, useState } from "react"
import { AddNewCategory, getCategory } from "../../Config/firebase"

export default function AddCategory() {
    const [CategeryName, setCategoryName] = useState('')
    const [path, setPath] = useState('')
    const [metaDescription , setMetaDescription] = useState('')
    const [tags , setTags] = useState('')

    async function addCategory() {
        if (!CategeryName, !path , !metaDescription , !tags) {
            return alert('please select catogery')
        }

        await AddNewCategory(CategeryName, path , metaDescription , tags)
            .then(() => {
                setCategoryName('')
                setPath('')
                setMetaDescription('')
                setTags('')
                alert('Hogaya add')
            })
    }


    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        AddCategory
                    </h2>
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Catogery Name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                value={CategeryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Path Name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                value={path}
                                onChange={(e) => setPath(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Tags also add (,)
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>


                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Meta Description
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                value={metaDescription}
                                onChange={(e) => setMetaDescription(e.target.value)}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>


                    <div>
                        <button
                            onClick={addCategory}
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Add New Category
                        </button>
                    </div>



                </div>
            </div>
        </>

    )
}