import { Helmet } from "react-helmet";
import AdSense from "react-adsense";
import AdManagerComponent from "./AdXAd";
import Adsssss2 from "./ad2";
import BannerHeader from "./bannerads";
import Adsssss1 from "./ad1";
import Adsssss3 from "./ad3";
import Bannerads2 from "./bannerads2";
import Bannerads3 from "./bannerads3";
import Bannerads4 from "./bannerads4";
import { useNavigate } from "react-router-dom";
import Bannerads5 from "./bannerads5";
import Bannerads6 from "./bannerads6";
function About() {
  const navigate = useNavigate();
  return (
    <>
        <div className=" items-center justify-center py-3 mt-3 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads2 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("Contact")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  Contact US
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads3 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("About")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  About US
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads4 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("Blogs")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  Blogs
                </button>
              </div>
            </div>
          </div>
        </div>
      <AdManagerComponent />
      <Helmet>
        <meta charSet="utf-8" />
        <title>About</title>
        <link rel="canonical" href="https://newskyplus.com/About" />
      </Helmet>
      <section className="flex items-center bg-stone-100 xl:h-screen font-poppins dark:bg-gray-800 ">
        <Adsssss3 />

    
        <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
          <div className="flex flex-wrap ">
            <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
              <div className="relative lg:max-w-md">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/pona-5efe6.appspot.com/o/Author%2Fsami-hassan.png?alt=media&token=27a42625-71bf-40be-b433-eca1aec2aad1&_gl=1*1l8q30d*_ga*MTQzNDAwMjgyNS4xNjk3MDY0NzQy*_ga_CW55HF8NVT*MTY5ODYzNzMyMS4zNi4xLjE2OTg2MzczODYuNjAuMC4w"
                  alt="aboutimage"
                  className="relative z-10 object-contain w-full rounded h-96"
                />
                <div className="absolute bottom-0 right-0 z-10 p-8 bg-white border-4 border-blue-500 rounded shadow dark:border-blue-400 lg:-mb-8 lg:-mr-11 sm:p-8 dark:text-gray-300 dark:bg-gray-800 ">
                  <p className="text-lg font-semibold md:w-72">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="absolute top-0 left-0 w-16 h-16 text-blue-700 dark:text-gray-300 opacity-10"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"></path>
                    </svg>{" "}
                    Our team of tech enthusiasts and experts dives deep into the
                    tech world, offering in-depth analysis and expert opinions
                    on a wide range of topics.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full px-6 mb-10 lg:w-1/2 lg:mb-0 ">
              <div className="pl-4 mb-6 border-l-4 border-blue-500 ">
                <span className="text-sm text-gray-600 uppercase dark:text-gray-400">
                  Who we are?
                </span>
                <h1 className="mt-2 text-3xl font-black text-gray-700 md:text-5xl dark:text-gray-300">
                  About Us
                </h1>
              </div>
              <p className="mb-6 text-base leading-7 text-gray-500 dark:text-gray-400">
                At NEWSKYPLUS.COM. Our mission is to keep you updated with the
                latest in the tech world. We are passionate about technology and
                committed to delivering daily tech news and insights and reviews
                that matter to you. We believe that staying informed about the
                fast-paced tech industry is crucial in today is world and we are
                here to make that process easier for you.
              </p>
              <a
                href="#"
                className="px-4 py-2 text-gray-100 bg-blue-500 rounded dark:bg-blue-400 dark:hover:bg-blue-500 hover:bg-blue-600"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads5 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("Signup")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  Signup
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads6 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("Signin")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  Signin
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default About;
