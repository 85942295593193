/* global googletag */
import React, { useEffect } from "react";

const Bannerads5 = () => {
  useEffect(() => {
    // Load GPT.js asynchronously
    const script = document.createElement("script");
    script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
    script.async = true;

    script.onload = () => {
      // Define ad slot and enable services
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function () {
        googletag
          .defineSlot(
            "/23060771193/Display",
            [[728, 90], [750, 100]], 'div-gpt-ad-1708228569194-0'
          )
          .addService(googletag.pubads());
        googletag.enableServices();
      });

      // Display ad
      googletag.cmd.push(function () {
        googletag.display('div-gpt-ad-1708228569194-0');
      });
    };
    document.head.appendChild(script);

    return () => {
      // Cleanup when component unmounts
      // This will remove the ad slot and any related resources
      googletag.cmd.push(function () {
        googletag.destroySlots();
      });
    };
  }, []); // Empty dependency array ensures the effect runs only once during component mount

  return (
    <div id='div-gpt-ad-1708228569194-0' className="w-auto h-auto">
      {/* Ad content will be displayed here */}
    </div>
  );
};

export default Bannerads5;