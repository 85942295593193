import { initializeApp } from "firebase/app";
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { deleteDoc } from "firebase/firestore";
import { deleteObject } from "firebase/storage";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyDl1L6W_rv4EY2OxoLHERuATh8_9wE9qEY",
  authDomain: "pona-5efe6.firebaseapp.com",
  projectId: "pona-5efe6",
  storageBucket: "pona-5efe6.appspot.com",
  messagingSenderId: "569348063445",
  appId: "1:569348063445:web:e5b509b4fc5dfd8488625c",
  measurementId: "G-0VJN1ZJEK6",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();
export const auth = getAuth(app);

async function AddNewCategory(CategeryName, path, tags, metaDescription) {
  try {
    await setDoc(doc(db, "Category", CategeryName), {
      CategeryName,
      path,
      tags,
      metaDescription,
    });
  } catch (e) {
    alert(e.message);
  }
}

async function getCategory() {
  const res = await getDocs(collection(db, "Category"));
  const list = [];
  res.forEach((doc) => {
    const getAllUsers = doc.data();
    list.push(getAllUsers);
  });
  return list;
}

async function addBlogPost(
  title,
  blogDetail,
  selectedCategery,
  metaDescription,
  metaTitle,
  currentDate,
  thumbnail,
  imageName,
  applynow
) {
  try {
    const url = await sendFile(thumbnail);

    const docPath = `${selectedCategery}/${metaTitle}`;

    await setDoc(doc(db, docPath), {
      title,
      blogDetail,
      selectedCategery,
      metaDescription,
      metaTitle,
      currentDate,
      imageName,
      applynow,
      image: url,
    });
  } catch (e) {
    alert(e.message);
  }
}

async function sendFile(thumbnail) {
  try {
    const imagesRef = ref(storage, "images/" + thumbnail.name);
    await uploadBytes(imagesRef, thumbnail);
    const url = getDownloadURL(imagesRef);
    return url;
  } catch (e) {
    alert(e.message);
  }
}

async function getBlogsCollection(initialPage) {
  const res = await getDocs(collection(db, initialPage));
  const list = [];
  res.forEach((doc) => {
    const getAllUsers = doc.data();
    getAllUsers.id = doc.id;
    list.push(getAllUsers);
  });
  return list;
}

async function getblogFullDetails(catogery, id) {
  try {
    const docRef = doc(db, catogery, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (e) {
    console.log(e.message);
  }
}

async function deletCategory(CategeryName) {
  await deleteDoc(doc(db, "Category", CategeryName));
}

async function deletblog(getBlogCategory, deletIndex) {
  try {
    await deleteDoc(doc(db, getBlogCategory, deletIndex));
  } catch (e) {
    alert(e.message);
  }
}

async function deletImage(imageName) {
  // Get a reference to the image in Firebase Storage
  const storage = getStorage();
  const imageRef = ref(storage, "images/" + imageName);

  try {
    // Delete the image
    await deleteObject(imageRef);
    console.log("Image deleted successfully:", imageName);
  } catch (e) {
    console.error("Error deleting image:", e.message);
  }
}

async function signInAdmin(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

async function userLogout() {
  await signOut(auth);
}

export {
  AddNewCategory,
  getCategory,
  addBlogPost,
  getBlogsCollection,
  getblogFullDetails,
  deletCategory,
  deletblog,
  deletImage,
  signInAdmin,
  userLogout,
};
