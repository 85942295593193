import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getblogFullDetails } from "../../Config/firebase";
import { useEffect, useState } from "react";
import AdSense from 'react-adsense';
import { Helmet } from "react-helmet";
import AdManagerComponent from "./AdXAd";
import Adsssss2 from "./ad2";
import Adsssss1 from "./ad1";
import Adsssss3 from "./ad3";
import Bannerads1 from "./bannerads1";
import Bannerads2 from "./bannerads2";
import Bannerads5 from "./bannerads5";
import Bannerads6 from "./bannerads6";
import Bannerads4 from "./bannerads4";
import Bannerads3 from "./bannerads3";

function BlogDetail() {
  const parm = useParams()
  const aino = useLocation()
  const navigate = useNavigate()
  const category = aino.pathname.split('/')

  const [data, setData] = useState()
  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    try {
      const res = await getblogFullDetails(category[1], parm.id)
      setData(res)
    }
    catch (e) {
      console.log(e.message)
    }

  }

  if (!data) {
    return <h1>Loading</h1>
  }


  return (
    <>
         
         <Helmet>
          <title>{data.title}</title>
          <link rel="canonical" href={"https://newskyplus.com/"+category[1] + "/" + parm.id} />
          <meta name="description" content={data.metaDescription}/>
          <meta name="author" content="Sami Hassan" />
        </Helmet>
        <div className=" items-center justify-center py-3 mt-3 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
            <Bannerads2 />
            <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
              <div className="max-w-md mx-auto space-y-6">
                <div className="flex flex-col">
                  <h1 className="mb-3 text-3xl font-extrabold text-center">
                    Pages
                  </h1>
                  <button
                    onClick={() => navigate("Contact")}
                    className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                  >
                    Contact US
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
            <Bannerads3 />
            <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
              <div className="max-w-md mx-auto space-y-6">
                <div className="flex flex-col">
                  <h1 className="mb-3 text-3xl font-extrabold text-center">
                    Pages
                  </h1>
                  <button
                    onClick={() => navigate("About")}
                    className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                  >
                    About US
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
            <Bannerads4 />
            <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
              <div className="max-w-md mx-auto space-y-6">
                <div className="flex flex-col">
                  <h1 className="mb-3 text-3xl font-extrabold text-center">
                    Pages
                  </h1>
                  <button
                    onClick={() => navigate("Blogs")}
                    className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                  >
                    Blogs
                  </button>
                </div>
              </div>
            </div>
          </div>
     
      <div className="mt-3 bg-white">
        <Adsssss3/>
        <div className="">
          {/*author*/}
          <div className="w-[90%] px-3 py-6 mx-auto bg-gray-50">
            <a
              className="block transition duration-200 ease-out transform"
            >
              <img
                className="object-cover w-full shadow-sm max-h-64"
                alt={data.imageName}
                src={data.image} />
                
            </a>
            {/*post categories*/}
            <div className="flex items-center justify-start mt-4 mb-4">
              <a
                onClick={() => navigate('/blogs')}
                className="px-2 py-1 font-bold bg-red-400 text-white rounded-lg hover:bg-gray-500 mr-4"
              >
                {data.selectedCategery}
              </a>
            </div>
            <Bannerads1/>
            <div className="mt-2">
              {/*post heading*/}
              <a
                href="#"
                className="sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-purple-500  hover:underline"
              >
                {data.title}
              </a>


              {/*post views*/}
              <div className="flex justify-start items-center mt-2">
                <p className="text-sm text-green-500 font-bold bg-gray-100 rounded-full py-2 px-2 hover:text-red-500">
                  {data.currentDate}
                </p>
                <p className="text-sm text-gray-400 font-bold ml-5">Views</p>
              </div>

          <Adsssss2/>

            </div>
            {/*end post header*/}
            {/*post content*/}
            <div className="w-full   mx-auto l text-gray-700 mt-4 rounded bg-gray-100">
              {/*content body*/}
              <div>
                <div className="mt-2 w-full" dangerouslySetInnerHTML={{ __html: data.blogDetail }}>
                </div>
              </div>
              <Adsssss1/>
            </div>
            <a className="mx-auto " href={data.applynow} target="_blank">
              <button type="button" className="focus:outline-none mx-auto mt-9  text-white bg-blue-600 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">Apply Now</button>
            </a>

            <Bannerads2/>
          </div>





        </div>
      </div>
      <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads5 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("Signup")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  Signup
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
          <Bannerads6 />
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col">
                <h1 className="mb-3 text-3xl font-extrabold text-center">
                  Pages
                </h1>
                <button
                  onClick={() => navigate("Signin")}
                  className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                >
                  Signin
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default BlogDetail;


