import { useEffect, useMemo, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CheckIcon } from '@heroicons/react/20/solid'
import { addBlogPost, getCategory } from '../../Config/firebase';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import 'react-quill/dist/quill.snow.css';



export default function UploadBlog() {


  const navigate = useNavigate()

  const [title, setTitle] = useState('')
  const [thumbnail, setThumnail] = useState([])
  const [blogDetail, setBlogDetail] = useState('')
  const [selectedCategery, setSelectedCategery] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [applaynow, setApplyNow] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [categery, setCategery] = useState([])
  const [currentDate, setCurrentDate] = useState('')

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDateStore = `${day}-${month}-${year}`;


  useEffect(() => {
    setCurrentDate(currentDateStore)
    getAllCategory()
  }, [])

  console.log(thumbnail)
  function seenmeta(e) {
    const inputText = e.target.value;
    const convertedText = inputText.replace(/ /g, '-');
    setMetaTitle(convertedText)
  }



  async function getAllCategory() {
    const allCatogery = await getCategory()
    setCategery(allCatogery)
  }

  async function uploadBlogPost() {
    const getallvalues = await addBlogPost(
      title,
      blogDetail,
      selectedCategery,
      metaDescription,
      metaTitle,
      currentDate,
      thumbnail[0],
      thumbnail[0].name,
      applaynow


    )
    navigate('/blogs')

  }

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', "strike"],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['image', "link", "video"],
        ['clean'],
        ['code-block'],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
      ],
      imageResize: {
        displaySize: true
      }
    },
  }), [])


  return (<>

    <div className="flex ml-auto mr-auto min-h-full w-[80%] flex-col justify-center   ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>UploadBlog</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="">

        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Uload A Blog Post
        </h2>
      </div>

      <div className="">

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Article Main Haiding
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => setTitle(e.target.value)}
              type="text"

              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Url Title
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => seenmeta(e)}
              type="text"
              value={metaTitle}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            META Description
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => setMetaDescription(e.target.value)}
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Thumbnail
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => setThumnail(e.target.files)}
              type="File"
              required
              className="block w-full  py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Category
          </label>
          <div className="mt-2">
            <select
              required
              onChange={(e) => setSelectedCategery(e.target.value)}
              value={selectedCategery}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select a category</option> {/* Empty option as a placeholder */}
              {categery.map((item, index) => (
                <option key={index} value={item.path}>
                  {item.CategeryName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Apply Now links Paste Here
          </label>
          <div className="mt-2">
            <input
              value={applaynow}
              onChange={(e) => setApplyNow(e.target.value)}
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>


        <div>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Write Full Detail Blog
          </label>
          <div className="mt-2">
            <ReactQuill
              required
              className="block w-full   py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              theme="snow" value={blogDetail} modules={modules} onChange={setBlogDetail} />
          </div>
        </div>



        <span className="">
          <button
            onClick={uploadBlogPost}
            type="button"
            className="inline-flex  ml-auto mr-auto items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <CheckIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Publish
          </button>
        </span>


      </div>
    </div>
  </>
  )
}
