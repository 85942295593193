import { useLoaderData, useNavigate } from "react-router-dom";
import { getBlogsCollection, getCategory } from "../../Config/firebase";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Adsssss3 from "./ad3";
import Adsssss2 from "./ad2";
import Adsssss1 from "./ad1";
import Bannerads2 from "./bannerads2";
import Bannerads3 from "./bannerads3";
import Bannerads4 from "./bannerads4";
import Bannerads5 from "./bannerads5";
import Bannerads6 from "./bannerads6";

export default function Blogs() {
  const categeryRoute = useLoaderData();

  const navigate = useNavigate();
  const [initialPage, setInitialPage] = useState("Jobs");
  const [blogs, setBlogs] = useState([]);
  const [categery, setCategery] = useState(categeryRoute);

  useEffect(() => {
    getBlogsInDatabase(initialPage);
  }, [initialPage]);
  async function getBlogsInDatabase(initialPage) {
    const list = await getBlogsCollection(initialPage);
    setBlogs(list);
  }

  return (
    <>
      <div className="bg-white mt-7 ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{initialPage}</title>
          <link rel="canonical" href="https://newskyplus.com/Blogs" />
          <meta name="description" content={categery[0].metaDescription} />
          <meta name="keywords" content={categery[0].tags} />
          <meta name="author" content="Sami Hassan" />
        </Helmet>

        <div className=" overflow-scroll flex-initial">
          {categery.map((option, index) => {
            return (
              <button
                key={index}
                onClick={(e) => {
                  setInitialPage(option.path);
                }}
                className=" border  m-2 bg-blue-600 text-white  hover:bg-amber-300 hover:text-black ,
        rounded-md px-8 py-3 text-sm font-medium"
              >
                {option.CategeryName}
              </button>
            );
          })}
        </div>

        <Adsssss3 />

        <section className="flex items-center  bg-white  font-poppins ">
          <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto text-left lg:py-10 ">
            <div className="mb-10 text-center">
              <span className="block mb-4 text-xs font-semibold leading-4 tracking-widest text-center text-blue-500 uppercase dark:text-gray-400">
                Our {initialPage}
              </span>
              <h1 className="text-3xl font-bold capitalize dark:text-white">
                {initialPage} List
              </h1>
            </div>
            <Adsssss1 />

            <div className=" items-center justify-center py-3 mt-3 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
              <Bannerads2 />
              <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
                <div className="max-w-md mx-auto space-y-6">
                  <div className="flex flex-col">
                    <h1 className="mb-3 text-3xl font-extrabold text-center">
                      Pages
                    </h1>
                    <button
                      onClick={() => navigate("Contact")}
                      className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                    >
                      Contact US
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
              <Bannerads3 />
              <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
                <div className="max-w-md mx-auto space-y-6">
                  <div className="flex flex-col">
                    <h1 className="mb-3 text-3xl font-extrabold text-center">
                      Pages
                    </h1>
                    <button
                      onClick={() => navigate("About")}
                      className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                    >
                      About US
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
              <Bannerads4 />
              <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
                <div className="max-w-md mx-auto space-y-6">
                  <div className="flex flex-col">
                    <h1 className="mb-3 text-3xl font-extrabold text-center">
                      Pages
                    </h1>
                    <button
                      onClick={() => navigate("Blogs")}
                      className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
                    >
                      Blogs
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {blogs.map((post, index) => {
                return (
                  <>
              

                    <div
                      key={index}
                      onClick={() =>
                        navigate("/" + initialPage + "/" + post.id)
                      }
                      className="grid border m-2 grid-cols-1 lg:grid-cols-[30%,1fr]   gap-x-6 gap-y-4 "
                    >
                      <img
                        className="object-contain w-full rounded-md lg:w-96 h-64"
                        src={post.image}
                        alt=" "
                      />
                      <div className="flex items-center ">
                        <div>
                          <a className="inline-block mt-3 text-xs font-semibold text-blue-500 uppercase hover:text-blue-600 dark:text-blue-400 md:text-sm">
                            {post.selectedCategery}
                          </a>
                          <a>
                            <h2 className="mt-3 text-xl font-semibold leading-6 text-gray-900 dark:hover:text-blue-400 dark:text-gray-300 hover:text-blue-600 lg:leading-7 lg:text-2xl ">
                              {post.title}
                            </h2>
                          </a>
                          <p className="hidden mt-3 text-base leading-7 text-gray-700 dark:text-gray-400 md:block lg:text-lg ">
                            {post.metaDescription}
                          </p>
                          <div className="flex flex-wrap items-center mt-3 space-x-2 ">
                            <span className="text-xs font-semibold text-gray-700 uppercase dark:text-gray-500 lg:text-base ">
                              <span className="font-medium">BY </span> Sami
                              Hassan
                            </span>
                            <span className="text-xs text-gray-700 lg:text-base dark:text-gray-500">
                              |&nbsp; {post.currentDate}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>
        <Adsssss2 />
      </div>
      <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
        <Bannerads5 />
        <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
          <div className="max-w-md mx-auto space-y-6">
            <div className="flex flex-col">
              <h1 className="mb-3 text-3xl font-extrabold text-center">
                Pages
              </h1>
              <button
                onClick={() => navigate("Signup")}
                className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
              >
                Signup
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className=" items-center justify-center py-3 mt-2 from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br">
        <Bannerads6 />
        <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
          <div className="max-w-md mx-auto space-y-6">
            <div className="flex flex-col">
              <h1 className="mb-3 text-3xl font-extrabold text-center">
                Pages
              </h1>
              <button
                onClick={() => navigate("Signin")}
                className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg hover:bg-indigo-800 h-12 px-6 m-2 text-lg"
              >
                Signin
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const Test = async () => {
  const tuc = await getCategory();
  return tuc;
};
