
import { Helmet } from "react-helmet";
import { Router } from "./Config/router";




function App() {
  return (
    <>
 
    <Router/>
    </>

  );
}

export default App;
