import { useLoaderData, useNavigate } from "react-router-dom"
import { deletImage, deletblog, getBlogsCollection, getCategory } from "../../Config/firebase"
import { useEffect, useState } from "react"

function DeletBlog() {
    const getCategory = useLoaderData()

    const navigate = useNavigate()
    const [initialPage, setInitialPage] = useState('Jobs')
    const [blogs, setBlogs] = useState([])
    const [categery, setCategery] = useState(getCategory)


    useEffect(() => {
        getBlogsInDatabase(initialPage)
    }, [initialPage, blogs])

    async function getBlogsInDatabase(initialPage) {
        const list = await getBlogsCollection(initialPage)
        setBlogs(list)
    }

  
    async function senddata(index) {
        try {
            console.log(blogs[index])
            console.log(blogs[index].selectedCategery)
            console.log(blogs[index].metaTitle)
            await deletblog(blogs[index].selectedCategery , blogs[index].metaTitle  )
            await deletImage(blogs[index].imageName)
            alert('done')
        }
        catch (e) {
            alert(e.message)
        }

    }
    return (<div className="bg-white mt-7 ">

        <div className='text-center'>
            {
                categery.map((option, index) => {
                    return (<button key={index} onClick={(e) => { setInitialPage(option.path) }}
                        className=" border  m-2 hover:bg-amber-300 ,
      rounded-md px-3 py-2 text-sm font-medium">{option.CategeryName}</button>)
                })
            }


        </div>
        <section className="flex items-center py-10 bg-white lg:py-24 font-poppins ">
            <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto text-left lg:py-10 ">
                <div className="mb-10 text-center">
                    <span className="block mb-4 text-xs font-semibold leading-4 tracking-widest text-center text-blue-500 uppercase dark:text-gray-400">
                        Our blog
                    </span>
                    <h1 className="text-3xl font-bold capitalize dark:text-white">
                        Blog List
                    </h1>
                </div>
                <div>


                    {blogs.map((post , index) => {
                        return (
                            <div key={index} className="grid border grid-cols-1 lg:grid-cols-[30%,1fr]   gap-x-6 gap-y-4  mb-6">
                                <img
                                    className="object-contain w-full rounded-md lg:w-96 h-96"
                                    src={post.image}
                                    alt=" "
                                />
                                <div className="flex items-center ">
                                    <div>
                                        <a

                                            className="inline-block mt-3 text-xs font-semibold text-blue-500 uppercase hover:text-blue-600 dark:text-blue-400 md:text-sm"
                                        >
                                            {post.selectedCategery}
                                        </a>
                                        <a >
                                            <h2 className="mt-3 text-xl font-semibold leading-6 text-gray-900 dark:hover:text-blue-400 dark:text-gray-300 hover:text-blue-600 lg:leading-7 lg:text-2xl ">
                                                {post.title}
                                            </h2>
                                        </a>
                                        <p className="hidden mt-3 text-base leading-7 text-gray-700 dark:text-gray-400 md:block lg:text-lg ">
                                            {post.metaDescription}
                                        </p>
                                        <div className="flex flex-wrap items-center mt-3 space-x-2 ">
                                            <span className="text-xs font-semibold text-gray-700 uppercase dark:text-gray-500 lg:text-base ">
                                                <span className="font-medium">BY </span> Kios Kripkror
                                            </span>
                                            <span className="text-xs text-gray-700 lg:text-base dark:text-gray-500">
                                                |&nbsp;  {post.currentDate}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <button
                                    onClick={() => {
                                      
                                        senddata(index)
                                    }

                                    }
                                    className="middle none center rounded-lg bg-red-600 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    data-ripple-light="true"
                                >
                                    DeletBlog
                                </button>
                            </div>)
                    })}




                </div>
            </div>
        </section>

    </div>);
}

export default DeletBlog;




export async function getAllCategoryDelet() {
    const allCatogery = await getCategory()
    return allCatogery
}