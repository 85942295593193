import React from "react";
import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    useNavigate,
} from "react-router-dom";
import Header from "../Components/Screen/Header";
import Signin from "../Components/Screen/Singin";
import Signup from "../Components/Screen/Signup";
import Blogs, { Test } from "../Components/Screen/Blogs";
import UploadBlog from "../Components/Screen/Uploadblog";
import AuthorDetail from "../Components/Screen/AuthorDetail";
import Contact from "../Components/Screen/Contact";
import AddCategory from "../Components/Screen/AddCategory";
import BlogDetail from "../Components/Screen/Blogdetail";

import Home from "../Components/Screen/Home"
import DeletCategory, { getAllCategory } from "../Components/Screen/DeletCategory";
import About from "../Components/Screen/About";
import Footer from "../Components/Screen/Footer";
import DeletBlog, { getAllCategoryDelet } from "../Components/Screen/DeletBlog";
import Admin from "../Components/Screen/Admin";
import { auth } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import OurTeam from "../Components/Screen/OurTeam";



const Router = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Dashboard />,
            children: [
                {
                    path: "/",
                    loader :  Test      
                    ,
                    element: <Home />,
                },
                {
                    path: "About",
                    element: <About />,
                },
                {
                    path: "OurTeam",
                    element: <OurTeam />,
                },
                {
                    path: "Contact",
                    element: <Contact />,
                },
                {
                    path: "Signin",
                    element: <Signin />,
                },
                {
                    path: "Signup",
                    element: <Signup />,
                },
                {
                    path: "Blogs",
                    loader :  Test      
                    ,
                    element: <Blogs />,
                },
                {
                    path: "/:category/:id",
                    element: <BlogDetail />,
                },


                {
                    path: "UploadBlog",
                    element: <UploadBlog />,
                },
                {
                    path: "AddCategory",
                    element: <AddCategory />,
                },
                {
                    path: "DeletCategory",
                    loader :getAllCategory ,
                    element: <DeletCategory />
                },
                {
                    path: "Author",
                    element: <AuthorDetail />,
                },
                {
                    path: "deletBlog",
                    loader : getAllCategoryDelet,
                    element: <DeletBlog />,
                },
                {
                    path: "admin",
                    element: <Admin />,
                },

            ],
        },
    ]);
    return <RouterProvider router={router} />;
};

function Dashboard() {

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
}

export { Router };
