import { useEffect, useState } from "react"

import { addBlogPost, deletCategory, getCategory } from '../../Config/firebase';
import { useLoaderData, useNavigate } from "react-router-dom";

export default function DeletCategory() {

    const getCategoryRoute = useLoaderData()
    const [selectedCategery, setSelectedCategery] = useState()
    const [categery, setCategery] = useState(getCategoryRoute)
    const navigate = useNavigate()

    useEffect(() => {
    }, [categery])

    async function deleteSelectedCategory() {
        try {
            await deletCategory(selectedCategery)
                .then(() => navigate('/Blogs'))
        }
        catch (e) {
            alert(e.message)
        }
    }


    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                    />
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        AddCategory
                    </h2>
                </div>


                <div>
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                            Category
                        </label>
                        <div className="mt-2">
                            <select
                                required
                                onChange={(e) => setSelectedCategery(e.target.value)}
                                value={selectedCategery}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="">Select a category</option> {/* Empty option as a placeholder */}
                                {categery.map((item, index) => (
                                    <option key={index} value={item.path}>
                                        {item.CategeryName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div>
                        {
                            selectedCategery && <button
                                onClick={deleteSelectedCategory}
                                type="submit"
                                className="flex  w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Delet Category
                            </button>
                        }
                    </div>
                </div>





            </div>

        </>

    )
}




export async function getAllCategory() {
    const allCatogery = await getCategory()
    return allCatogery
}