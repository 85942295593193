import { PaperClipIcon } from '@heroicons/react/20/solid'

export default function AuthorDetail() {
  return (
    <div className='w-[100%]'>
       <div className='w-[80%] ml-auto mr-auto mt-6 '>
      <div className="px-4 sm:px-0 ">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Author Detail</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details on Author.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Sami Hassan</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Application for</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Front End Developer</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">samihassanofficial@gmail.com</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Salary expectation</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">$12/h</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            Sami Hassan is a skilled Front End Developer with a strong focus on MERN
            <strong>(MongoDB, Express.js, React, Node.js)</strong>  technology stack. Currently pursuing a Bachelor's degree in Computer Science, Sami combines academic knowledge with practical experience to create user-friendly and efficient web applications. With 1 year of experience in web development, Sami is dedicated to staying on the cutting edge of 
            technology and delivering top-notch solutions to clients and end-users.
            </dd>
          </div>
        </dl>
      </div>
    </div>
    </div>
  )
}
